import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Image, Form, Button, Alert, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/img/logo_negro.png';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ProductDetailCard from './productDetailCard';
import ProductResumeCard from './productResumeCard';
import { useHistory } from 'react-router-dom';
import '../../assets/css/signup.css';
import '../../assets/css/baseLayout.css';
import ObexAlert from '../basicComponents/ObexAlerts';
import { validatePass } from '../../services/validationService';


type SignupProps = {
  extraRequestsPlan: number,
  extraProjectsPlan: number,
  planID: number,
  totalPriceMonthly: number,
  totalPriceYear: number,
  nameProject: string,
  pricePlanMonthly: number,
  pricePlanYear: number,
  projectPriceMonthly: number,
  projectPriceYear: number,
  txPriceMonthly:number,
  txPriceYear:number,
  txDesc:string,
  projectDesc:string
}

const SignUp:FunctionComponent<SignupProps> = (props:SignupProps) => {

  const { 
    extraRequestsPlan,
    extraProjectsPlan,
    planID,
    totalPriceMonthly,
    totalPriceYear,
    nameProject,
    pricePlanMonthly,
    pricePlanYear,
    projectPriceMonthly,
    projectPriceYear,
    txPriceMonthly,
    txPriceYear,
    txDesc,
    projectDesc
  } = props;

  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [annualPayment, setAnnualPayment] = useState(false);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCVV] = useState('');
  const [conditions, setConditions] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {setShowPassword(!showPassword)};
  const handleConditions = () => {setConditions(!conditions)};
  const [ListCountries, setCountriesL] = useState([]);
  const [cargado, setCargado] = useState(false);
  const [alerta, setAlerta] = useState(false);

  const yearInputRef = useRef(null);
  const cvvInputRef = useRef(null);

  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }

  
  const changeCardName = (e) => {
    setCardName(e.target.value);
  }

  const changeCardNumber = (e) => {
    let newCardNumber = e.target.value.split(' ').join(''); // remove hyphens
    setCardNumber(newCardNumber);
    if (newCardNumber.length > 0) {
      newCardNumber = newCardNumber.match(new RegExp('.{1,4}', 'g')).join(' ');
    }
    e.target.value = newCardNumber;
  }

  const changeMonth = (e) => {
    const newMonth = e.target.value;
    setMonth(newMonth);
    if (newMonth.toString().length == e.target.maxLength){
      yearInputRef.current.focus();
    }
  }

  const changeYear = (e) => {
    const newYear = e.target.value;
    setYear(newYear);
    if (newYear.toString().length == e.target.maxLength){
      cvvInputRef.current.focus();
    }
  }

  const changeCVV = (e) => {
    const newCVV = e.target.value;
    setCVV(newCVV);
  }

  const handleSwitch = () => {
    setAnnualPayment(!annualPayment);
  }

  const handleValidatePass = (e) => {
    if (!validatePass(e.target.value)){
      setError('The password does not contain more than 8 characters, it includes at least 1 letter and number');
    }else{
      setError('');
    }
  }

  const getCountries = async () => {
    try {
      if (!cargado){
      const result = (await ObexRequestsHandler.get('/countries')).data;
      setCargado(true);
      setCountries(result);
      const countriesList = result.map(c => <option key={'getCountriesSigh'+c.country_code+Math.random()} value={c.country_code} selected={locality == c.country_code }>{c.country}</option>);
      setCountriesL(countriesList);
      }
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }

  }

  const [countries, setCountries] = useState([]);
  const [locality, setLocality] = useState('');
  

  const history = useHistory();

  function routeLogin() {
    history.push({
      pathname: '/verify_signup'
    })
  }

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {

    getCountries();

    if (mail && password && cardNumber && month && year && cvv && conditions){
      setDisabled(false);
    }else{
      setDisabled(true);
    }
    
  }, [mail, password, cardNumber, month, year, cvv, conditions])

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setError('');// Limpiamos los posibles errores
    setLoading(true);
    const payload = {
      email: mail,
      password: password,
      username: mail,
      subscription: planID,
      annualPayment: annualPayment,
      cardNumber: cardNumber,
      cardMonthExp: month,
      cardYearExp: year,
      cardCvv: cvv,
      cardName: cardName,
      extraProjectsPlan: extraProjectsPlan,
      extraRequestsPlan: extraRequestsPlan,
      countryCode: 'MX' 
    }

    const result = await ObexRequestsHandler.post('/signup', payload);
    
    try{
    if ('next_action' in result['data']){
      setAlerta(true);
      open(result['data']['next_action']['use_stripe_sdk']['stripe_js']);
      console.log(result['data']['payment_id']);

      let completed = false;
      const payload2 = {
        setup_id: result['data']['setup_id'].toString(),
      }
      console.log(payload2);

      let codigo_llamada = 500;

      while (!completed){
        await sleep(5000);
        //console.log(`comprobando pago ${result["data"]["payment_id"]}`);

        try{
        const resulta = await ObexRequestsHandler.post('/checkauthsetup', payload2);
        //console.log(resulta);

        if ('data' in resulta){
          if ('code' in resulta['data']){
            if (resulta['data']['code']!=100) completed = true;
            codigo_llamada = resulta['data']['code'];
          }
        }

        } catch{
          console.log(payload2);
        }

      }

      if (codigo_llamada == 500){
        setError('Cannot SignUp');
        setLoading(false);
      }
      else if (codigo_llamada == 200){

        const payload = {
          email: mail,
          password: password,
          username: mail,
          subscription: planID,
          annualPayment: annualPayment,
        //  payment_id: result["data"]["payment_id"].toString(),
          customer_id: result['data']['customer_id'].toString(),
          card_token: result['data']['card_token'].toString(),
          cardName: cardName,
          extraProjectsPlan: extraProjectsPlan,
          extraRequestsPlan: extraRequestsPlan,
          countryCode: 'MX' 
        }
    
        const resulti = await ObexRequestsHandler.post('/finish_signup', payload);


        const { success, message } = resulti;
        if (success){
          if (resulti['data'].toString().includes('created')){
            setSuccess(true);
            setLoading(false);
            routeLogin();
          }
      }else {
        setError(message);
        setLoading(false);
      } 



      } else {
        setError('Connection Error');
        setLoading(false);
      }

    }

    }
    catch{
      const { success, message } = result;
      if (success){
        if (result['data'].toString().includes('created')){
          setSuccess(true);
          setLoading(false);
          routeLogin();
        }
    }else {
      setError(message);
      setLoading(false);
    } 
  }  
  };

  return(
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col md={7}>
          <Row className="h-100">
            <Col md="8" className="mx-auto my-auto">
              <Row className="h-100">
                <Col xs="5" className="mx-auto mb-4">
                  <Image src={Logo} fluid/>
                </Col>
                <Col md="12" className="text-center">
                  <h1 className="mb-3">Let's Get Started</h1>
                  <p>Review your plan and create an account</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit} className="signup-form obex-form">
                    <Form.Row className="mb-3">
                      <Col>
                        <Form.Control required type="email" placeholder="Email address" onChange={changeMail}/>
                      </Col>
                      {/* <Form.Check type="checkbox" label="Use email address as username" /> */}
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Col>
                        <InputGroup className="obex-input-group mb-3">
                          <FormControl
                            placeholder="Password"
                            aria-describedby="password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={changePass} onBlur={handleValidatePass}
                          />
                          <InputGroup.Append>
                            <Button onClick={handleShowPassword} className="text-black">
                              {showPassword ? 
                                <FontAwesomeIcon icon={faEyeSlash}/> : 
                                <FontAwesomeIcon icon={faEye}/>}
                                </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <span>Must have more than 8 characters, including at least 1 letter and number.</span>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Col>
                        <Form.Control required type="text" placeholder="Cardholder full name" onChange={changeCardName}/>
                      </Col>
                    </Form.Row>
                    <Form.Group>
                      <Form.Row className="info-credit-card">
                        <Col md="12">
                          <Form.Control
                            required
                            type="text"
                            maxLength={19}
                            placeholder="Card Number"
                            onChange={changeCardNumber}
                          />
                        </Col>
                      </Form.Row> 
                    </Form.Group>
                    <Form.Group>
                      <Form.Row className="info-credit-card">
                        
                        <Col md="2">
                          <Form.Control required type="text" minLength={2} maxLength={2} placeholder="MM" onChange={changeMonth}/>
                        </Col>
                        <Col md="2">
                          <Form.Control
                            required
                            ref={yearInputRef}
                            type="text"
                            minLength={4}
                            maxLength={4}
                            placeholder="YYYY"
                            onChange={changeYear}
                          />
                        </Col>
                        <Col md="2">
                          <Form.Control 
                            required
                            ref={cvvInputRef}
                            type="text" 
                            minLength={3} 
                            maxLength={4} 
                            placeholder="CVV" 
                            onChange={changeCVV}
                          />
                        </Col>
                        <Col>
                        <Form.Control required as="select" placeholder='Country' custom onChange={null}>
                          <option value="" selected disabled hidden>Choose country</option>
                            {ListCountries}
                        </Form.Control>
                        </Col>


                      </Form.Row>
                    </Form.Group>
                    <Form.Row key="custom-checkbox">
                      <Col md="1" style={{ maxWidth:'24px' }}>
                        <div key={'custom-checkbox'}>
                          <Form.Check 
                            custom
                            type="checkbox"
                            id={'custom-checkbox'}
                            label="" 
                            onClick={handleConditions}
                          />
                          
                        </div>
                        
                      </Col>
                      <Col md="11" className='pt-1 mb-2'>
                      I accept the 
                      <a                            
                            target="_blank" rel="noopener noreferrer"
                            href="https://101obex.com/termsconditions"> Terms of Service </a>
                      and have read the 
                      <a                           
                            target="_blank" rel="noopener noreferrer"
                            href="https://101obex.com/privacypolicy"> Services Privacy Policy</a>
                          

                        </Col>
                    </Form.Row>

                    {alerta && (
                    <Alert variant='success'>
                      Please allow popup to complete payment.
                    </Alert>)
                    }


                    <Row>
                      <Col>
                        <ObexAlert type='error' msg={error}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mx-auto">
                        <Form.Row className="text-center">
                          <Button type="submit" className="my-2 obex-btn login btn-block" disabled={disabled}>
                            Continue
                            {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                          </Button>
                        </Form.Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={5} className="resume-plan">
          <Row className="h-100">
            <Col md="11" className="mx-auto my-auto">
              <Row className="">
                <Col>
                  <h3 className="title-products">Your Products</h3>
                </Col>
              </Row>
              <ProductDetailCard title={'Subscription'} 
                descrip={nameProject} 
                priceMonthly={pricePlanMonthly}  
                priceYear={pricePlanYear} 
                annualPayment={annualPayment}
                letMonths={10}
              />
              {extraProjectsPlan > 0 && 
                <ProductDetailCard title="Extra projects add on" 
                  descrip={projectDesc} 
                  priceMonthly={projectPriceMonthly} 
                  priceYear={projectPriceYear} 
                  annualPayment={annualPayment}
                  letMonths={10}
                />
              }
              {extraRequestsPlan > 0 &&
                <ProductDetailCard title="Extra requests add on" 
                  descrip={txDesc} 
                  priceMonthly={txPriceMonthly} 
                  priceYear={txPriceYear} 
                  annualPayment={annualPayment}
                  letMonths={10}
                />
              }
              <ProductResumeCard title="Total Price" 
                prorated={true} 
                priceMonthly={totalPriceMonthly} 
                priceYear={totalPriceYear} 
                annualPayment={annualPayment}
                letMonths={10}
              />
              {(nameProject!='Developers') &&
              <Form className="obex-form">
                <Form.Row>
                  <Col md="6">
                    <Form.Check 
          
                      type="switch"
                      id="custom-switch"
                      label="Annual Payment"
                      onChange={handleSwitch}
                      className="obex-switch"
                    />
                    </Col>
                    <Col md="6" className="text-right">
                      <span>*Taxes may apply</span>
                    </Col>
                </Form.Row>
              </Form>}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SignUp;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
