import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Card, Button, Spinner, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faInfo, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import UpgradePlanModal from '../../Modals/UpgradePlanModal';
import { useHistory } from 'react-router-dom';


type ConsumptionProps = {
  title: string,
  value: string,
  plus?: boolean,
  action?: () => void,
  loading: boolean,
  available: boolean,
  info: boolean
  currentSuscription: number
}

const ConsumptionCard:FunctionComponent<ConsumptionProps> = props => {
  const history = useHistory();
  const { title, value, plus, action, loading, available, info, currentSuscription } = props;
  const [showModal, setShowModal] = useState(false);
  
  const textModal = "To advance upgrade your plan"
  const accion = () =>{
      if (title=='Income') {history.push('/income');} else {
      if (available) action();
        else handleUpgradeModal();
      }
  }

  const handleUpgradeModal = () => {setShowModal(true)}
  const onCloseModal = () => {setShowModal(false)}

  return(
    <Col className="border-left comsumption-box pb-4 pt-3">
      <Row>
        <Col md="2" className="mt-1 pl-1 pr-0">
          <FontAwesomeIcon
            icon={plus ? faPlusCircle : faQuestionCircle}
            className={loading ? `btn-comsumption-grey ml-1` :`btn-comsumption ml-1`}
            size="2x"
            onClick={loading ? null : accion}
          />
        </Col>
        <Col className="comsumption-title pl-1">
          <span>{title}
          
          {info ? 
            <Tooltip style={{marginLeft : '-3em'}} title="In some cases this can be because timeout" id={'info'}>
            <FontAwesomeIcon
            icon={faPlusCircle}
            //className={loading ? `btn-comsumption-grey ml-1` :`btn-comsumption ml-1`}
            size="2x"/>
          </Tooltip>
            :
            <span></span>
          }</span>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 12, offset: 2 }} className="comsumption-text pl-2 mb-2">
          {info ?
          <span  style={{marginLeft : '-1em'}}>
            <FontAwesomeIcon icon={faInfo} size="1x"/></span>
          :
          <span style={{marginLeft : '-1em'}}></span>
          }
          {loading ?
            <Spinner style={{marginLeft : '+0.3em'}} animation="border"  size="sm"/>
            : <span  style={{marginLeft : '+0.3em'}} className="text-center">{value}</span>
          }
        </Col>
      </Row>
      <UpgradePlanModal 
          showModal={showModal} 
          text={textModal} 
          onCloseModal={onCloseModal} 
          currentSuscription={currentSuscription}/>
    </Col>
  )
}

export default ConsumptionCard;