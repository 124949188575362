import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button, Image  } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import ilustra_upgrade from '../../assets/img/upgrade.png';


type upgradePlanProps = {
  showModal: boolean,
  text: string,
  currentSuscription: number,
  onCloseModal: () => void,
}

const UpgradePlanModal:FunctionComponent<upgradePlanProps> = (props) => {
  
  const { showModal, onCloseModal, text, currentSuscription } = props
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const history = useHistory();
  const getSettingFormat = async() => {};

  const onUpgrade = () => {
    history.push(`/plan_subcription/upgrade_plan`);
  }

  const handleSubmit = (e) => {

  };

  useEffect(() => {
    //getSettingFormat();
  })

  const ContentModal = (
    <Form>
      <Form.Row>

      <Col md="5" className="my-3 mx-auto">
              <Image src={ilustra_upgrade}  fluid/>
            </Col>

      </Form.Row>



      <Form.Row>
        <Col>
          <ButtonSubmit 
            color={true}
            loading={loading} 
            success={success} 
            error={error} 
            handleSubmit={onCloseModal} 
            submitDisabled={submitDisabled} 
            textButton={'Close'}
          />
        </Col>
           
        <Col>
          <ButtonSubmit 
            loading={loading} 
            success={success} 
            error={error} 
            handleSubmit={onUpgrade} 
            submitDisabled={submitDisabled} 
            textButton={'Upgrade'}
          />
        </Col>
      </Form.Row>
    </Form>
  )

  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={showModal} 
      onHide={onCloseModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col md="12" className="addons-title">
              <span>Upgrade plan</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>

      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
        <Row>
          <Col md="12" className="text-center">
            <span>{text}</span>
          </Col>
        </Row>
       {ContentModal}
      </Modal.Body>
    </Modal>
  )
}

export default UpgradePlanModal;