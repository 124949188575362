import ObexRequestHandler from '../handlers/ObexRequestsHandler';
import Cookies from 'universal-cookie/cjs/Cookies';


class SessionService {
  private static secondsTimeout;
  private static checkInactivityInterval;
  private static sinceGetTokenInterval;
  private static secondsSinceGetToken  = 0;
  private static inactivitySecondCounter = 0;
  private static dispatchedInactivity = false;
  public static ORGANIZATION = 0;
  public static ORGANIZATION_NAME;
  public static DEV_ID;
  public static ORGANIZATIONS;

 
  // Evento para cuando se expira la sesión
  private static sessionExpiredEvent = document.createEvent('Event');
  private static inactivityEvent = document.createEvent('Event');

  private static restartInactivitySecondCounter = () => {
    if (!SessionService.dispatchedInactivity) SessionService.inactivitySecondCounter = 0;
  }



  public static async login(email: string, password: string, developer: boolean):Promise<any> {

    const closeSession = () =>{
      SessionService.logout();
      window.location.replace('/login');
    
    }

    try {
      SessionService.dispatchedInactivity = false;
      const payload = {
        email,
        password,
        developer
      }
      const result =  await ObexRequestHandler.post('/signin', payload);
      const { success, data, message } = result;
      if (!success) throw { message };
      const { secondsTokenExpiration, dev_id } =  data; // Data es la info del login, el token, si es admin etc.
      SessionService.DEV_ID = dev_id || 14;
      SessionService.secondsTimeout = secondsTokenExpiration;
      SessionService.sinceGetTokenInterval= setInterval(() => {
        SessionService.secondsSinceGetToken++;
        SessionService.inactivitySecondCounter++;
        const cookies = new Cookies();
        const userInfo = cookies.get('101Obex');
        if (!userInfo) {closeSession();}
     

      }, 1000);
      SessionService.setExpirationCountdown(data);
      document.addEventListener('click', SessionService.restartInactivitySecondCounter, false);
      document.addEventListener('mouseover', SessionService.restartInactivitySecondCounter, false);
      document.addEventListener('keypress', SessionService.restartInactivitySecondCounter, false);
      return data;
    } catch (error) {
      throw error;
    }
  }

  public static dispatchSessionExpiredEvent():void {
    SessionService.sessionExpiredEvent.initEvent('sessionExpiredEvent', true, true);
    document.dispatchEvent(SessionService.sessionExpiredEvent);
  }

  public static dispatchInactivityEvent():void {
    SessionService.inactivityEvent.initEvent('inactivityEvent', true, true);
    document.dispatchEvent(SessionService.inactivityEvent);
  }

  public static checkInactivity(expiration: number):void {
    // const allowInactivitySeonds = 60;
    if (!expiration) expiration = 570;
    if (SessionService.secondsSinceGetToken >= expiration || SessionService.inactivitySecondCounter> 200) {  // Se permite tambien entrar en cierre por tiempo inactivo
      if (SessionService.secondsSinceGetToken >= expiration)/*(SessionService.inactivitySecondCounter < allowInactivitySeonds)*/ {
        SessionService.renewSession();
      } else {
        if (!SessionService.dispatchedInactivity) {
          SessionService.dispatchInactivityEvent();
          SessionService.dispatchedInactivity = true;
        }
      }
    }
  }

  private static getExpirationDate():Date {
    const sessionExpiration = SessionService.secondsTimeout;
    const date = new Date();
    date.setSeconds(date.getSeconds() + sessionExpiration)
    return date;
  }

  public static async renewSession(comando=''):Promise<void> {

    const closeSession = () =>{
      SessionService.logout();
      window.location.replace('/login');
    
    }

    try {
      const result = await ObexRequestHandler.get('/session/renew');
      const { success, data, message } = result; // data es el token
      if (!success) throw { message };
      const { secondsTokenExpiration } =  data;
      SessionService.secondsTimeout = secondsTokenExpiration;
      const cookies = new Cookies();
      const userInfo = cookies.get('101Obex');
      userInfo.token = data; // Actualizamos el token
      const expirationdate = new Date(new Date().getTime()+600000);
      cookies.set('101Obex', JSON.stringify(userInfo), { path: '/', expires: expirationdate });
      SessionService.secondsSinceGetToken = 0;
      SessionService.dispatchedInactivity = false;


      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) { //TODO Controlar mejor la recarga

          // const expiresIn = (SessionService.secondsTimeout  - 30);

          clearInterval(SessionService.checkInactivityInterval); 
          clearInterval(SessionService.sinceGetTokenInterval); 

          SessionService.checkInactivityInterval = setInterval(() => SessionService.checkInactivity(secondsTokenExpiration), 1000);
          SessionService.sinceGetTokenInterval= setInterval(() => {
            SessionService.secondsSinceGetToken++;
            SessionService.inactivitySecondCounter++;
            const cookies = new Cookies();
            const userInfo = cookies.get('101Obex');
            if (!userInfo) {closeSession()}
            
            }, 1000);

          document.addEventListener('click', SessionService.restartInactivitySecondCounter, false);
          document.addEventListener('mouseover', SessionService.restartInactivitySecondCounter, false);
          document.addEventListener('keypress', SessionService.restartInactivitySecondCounter, false);
        }
      } catch (error) {
        throw error;
        }
       if (comando!='') window.location.replace('/dashboard');
  }

  private static setExpirationCountdown(userInfo):void {
    // const expirationDate = SessionService.getExpirationDate();
    const cookies = new Cookies();
    const expirationdate = new Date(new Date().getTime()+600000);
    cookies.set('101Obex', JSON.stringify(userInfo), { path: '/', expires: expirationdate });
    const expiresIn = (SessionService.secondsTimeout  - 30); // Damos 30 segundos de margen
    SessionService.checkInactivityInterval = setInterval(() => SessionService.checkInactivity(expiresIn), 1000);
  }

  public static async logout():Promise<void> {
    try {
      const cookies = new Cookies();
      cookies.remove('101Obex');
      clearInterval(SessionService.checkInactivityInterval);
      clearInterval(SessionService.sinceGetTokenInterval);
      SessionService.secondsSinceGetToken  = 0;
      SessionService.inactivitySecondCounter = 0;
      document.removeEventListener('click', SessionService.restartInactivitySecondCounter, false);
      document.removeEventListener('mouseover', SessionService.restartInactivitySecondCounter, false);
      document.removeEventListener('keypress', SessionService.restartInactivitySecondCounter, false);
      window.location.replace('/login');
    } catch (error) {
      window.location.replace('/login');
      throw error;

    }

  }

  public static isAdmin():boolean {
    const cookies = new Cookies();
    const sessionCookie = cookies.get('101Obex');
    if (sessionCookie && sessionCookie.admin){
      return true;
    }
    return false;
  } 

  public static isDev():boolean {
    const cookies = new Cookies();
    const sessionCookie = cookies.get('101Obex');
    console.log('IS DEV');
    console.log(sessionCookie);
    if (sessionCookie && sessionCookie.developer){
      return false;
    }
    return false;
  } 


}

export default SessionService;