import React, { FunctionComponent, Component, useEffect, useState, useRef } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Card, Form, Button, Spinner, InputGroup, FormControl, Container } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck  } from '@fortawesome/free-solid-svg-icons';
import { useParams, useHistory } from 'react-router-dom';
import ObexAlert from '../basicComponents/ObexAlerts';
import ConfirmDeleteProjectModal from '../Modals/ConfirmDeleteProjectModal';
import SessionService from '../../services/SessionsService';


type ProjectInfoProps = {
  projects: Array<any>
};

const projectStored = {
  name: '',
  description: '',
  apikey: '',
  benefits: 0,
  creation_date: '',
  cipherKey: '',
  tx: {
    limit: 0,
    current: 0,
    rest: 0
  },
  type: ''
}

const ProjectInfo:FunctionComponent<ProjectInfoProps> = (props) => {

  const history = useHistory();

  const { project } = useParams();
  const { projects } = props;
  var puntose = '';
  var contadore = 0;
  const [projectInfo, setProjectInfo] = useState(projectStored);

  const [oldProjectName, setoldProjectName] = useState('')
  const [projectName, setProjectName] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [projectLimit, setProjectLimit] = useState('');
  const [originalLimit, setOriginalLimit] = useState('');
  const [projectBenefits, setProjectBenefits] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAlert, setAlert] = useState(true);
  const IDProject = useRef(null);
  const projectAPIKey = useRef(null);
  const projectURL = useRef(null);
  const cipherKey = useRef(null);
  const [usuariosProj, setUsuarios] = useState([]);
  const [sublinks, setSublinks] = useState([{
    "path": `#`,
    "title": "Loading Data ",
  }]);
  const [stillLoaded, SetStillLoaded] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {setShowModal(!showModal)}

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [Copied, setCopied] = useState('');
  const [titulo, setTitulo] = useState('');

  const getProjectInfo = async () => {
    SetStillLoaded(true);
    const interval = setInterval(() => {
      if (contadore<5) {
        puntose = puntose + "."
        contadore++;

      } else {
        contadore  = 0;
        puntose='.';
      }
      setSublinks([{"path": `#`,"title": "Loading Data "+ puntose }]);
    }, 100);
    
    try {
      const usuarios = (await ObexRequestHandler.get(`/projects/${project}/users`, {}, true)).data || {};
      setUsuarios(usuarios);
      const projectInformation = (await ObexRequestHandler.get(`/projects/${project}`, {}, true)).data || {};
      const projectsStaging = (await ObexRequestHandler.get('/staging_projects', {}, true)).data || [];
      const pro = projects ? projects : (await ObexRequestHandler.get('/projects', {}, true)).data || []; // Si no recibe los projects por los props los rescatamos por request
      const sublinksProjects = []
      var nn;
      pro.map((project, i) => {
        if (projectCode == project.name && projectName!='') {nn=projectName;setProjectName('');} else nn=project.description; 
        var linkProject = {
          "path": project.name,
          "title": nn,
        }
        sublinksProjects.push(linkProject)
      });
      clearInterval(interval);
      setSublinks(sublinksProjects);
      setProjectInfo(projectInformation);
      setTitulo(projectInformation.description);
      setoldProjectName(projectInformation.description);
      setProjectCode(projectInformation.name);
      setProjectLimit(new Intl.NumberFormat('en-US').format((projectInformation.tx.limit)));
      setOriginalLimit(new Intl.NumberFormat('en-US').format(projectInformation.tx.limit));
      setProjectBenefits(projectInformation.benefits);
      SetStillLoaded(false);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    }
  }

  const alertaCopy = (identificador) =>{
    setCopied(identificador);
    setTimeout(() => { 
      setCopied('');
    }, 2000);

  }

  const updateProjectInfo = async () => {
    try {
      setLoading(true);
      setSuccess('');
      setError('');
      if (projectName) {
        const { success, data, message } = await ObexRequestHandler.put(`/projects/${project}/name`, { name: projectName });
        if (!success) throw { message };
      }
      if (projectLimit !== originalLimit) {
        var old_projectLimit = projectLimit.replace(".","");
        var old_projectLimit = old_projectLimit.replace(",","");
        const newProjectLimit = old_projectLimit;
        const { success, data, message } = await ObexRequestHandler.put(`/projects/${project}/rate-limit`, { rate: newProjectLimit });
        if (!success) throw { message };
      }
      setSuccess('Project updated');
      setTimeout(() => { 
        setSuccess('')
        setSubmitDisabled(true);
      }, 2000);


      setTitulo(projectName);
      getProjectInfo();
      //getProjectInfo();
    } catch (error) {
      setError(error.message || error);
      setLoading(false);
    }
    setLoading(false);
  }

  const handleProjectName = (e) => {
    setProjectName(e.target.value);
    setSubmitDisabled(false);
  }

  const handleProjectLimit = (e) => {
    var t = e.target.value.replace(",","");
    t = t.replace(".","");
    setProjectLimit(new Intl.NumberFormat('en-DE').format(t));
    e.target.value = new Intl.NumberFormat('en-DE').format(t);
    setSubmitDisabled(false);
  }

  const handleProjectBenefits = (e) => {
    setProjectBenefits(e.target.value);
    setSubmitDisabled(false);
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    e.stopPropagation();
    await updateProjectInfo();
  }

  const routeCommission = (project) => {
    history.push(`/projects/${project}/commissions`);
  }

  const handleRouteCurrencies = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { name } = projectInfo;
    history.push(`/projects/${name}/exchange_activated/`);
  }

  const deleteProject = async (project) => {
    try {
      setDeleting(true);
      const result = await ObexRequestHandler.delete(`/projects/${project}`);
      const { success, message, data } = result;
      if (!success) {
        setError(message)
      } else {
        history.push('/projects');
      }
    } catch (error) {
      setError('Project cannot be deleted.');
    }
    setDeleting(false);
    SessionService.renewSession();
  }

  const copyTextID = (e) => {
    IDProject.current.disabled = false;
    IDProject.current.select();
    document.execCommand('copy');
    IDProject.current.disabled = true;
  }

  const copyAPIKey = () => {
    projectAPIKey.current.disabled = false;
    projectAPIKey.current.select();
    document.execCommand('copy');
    projectAPIKey.current.disabled = true;
  }

  const copyURL = () => {
    projectURL.current.disabled = false;
    projectURL.current.select();
    document.execCommand('copy');
    projectURL.current.disabled = true;
  }

  const copyCypherKey = () => {
    cipherKey.current.disabled = false;
    cipherKey.current.select();
    document.execCommand('copy');
    cipherKey.current.disabled = true;
  }

  const handleDeleteProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteProject(projectInfo.name);
  }

  const handleRouteCommission = (e) => {
    e.preventDefault();
    e.stopPropagation();
    routeCommission(projectInfo.name);
  }
  
  useEffect(() => {
    getProjectInfo();
  }, [project]);

  return (
  <DashLayout sider={DashRouters} active={'projects'} sublinks={sublinks}>
              {stillLoaded ?

<Container className="vh-100 d-flex flex-column vw-100"> 
<Row className="h-100" >

            
          <Col md="1" className="mx-auto d-flex align-items-center">
            <Spinner animation="border"/>
          </Col>

        </Row>
        </Container>
          :
    <Row>
      <Col md="9" className="mx-auto">
        <Row>
          <Col>
            <h1 className="mb-3">{titulo}</h1>
          </Col>
        </Row>
        <Row>

          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Row>
                  <Col className="mx-auto">
                    <Form className="obex-form" onSubmit={handleSubmit}>
                      <Form.Row className="mb-3">
                          <Form.Label column md="3" className="obex-form-label">
                            Project Name
                          </Form.Label>
                          <Col>
                            <Form.Control type="text" defaultValue={projectInfo.description} onChange={handleProjectName}/>
                          </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                          <Form.Label column md="2" className="obex-form-label">
                            Type
                          </Form.Label>
                          <Col>
                            <Form.Control plaintext readOnly defaultValue={projectInfo.type}/>
                          </Col>
                          <Form.Label column md="2" className="obex-form-label">
                            Created
                          </Form.Label>
                          <Col>
                            <Form.Control plaintext readOnly defaultValue={projectInfo.creation_date} />
                          </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                          <Form.Label  column md="5" className="obex-form-label">
                            Monthly Request limit
                          </Form.Label>
                          <Col>
                            <Form.Control type="number" placeholder="limit Request monthly" value={projectLimit} onChange={handleProjectLimit}/>
                          </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                          <Form.Label column md="5" className="obex-form-label">
                            Monthly spending limit
                          </Form.Label>
                          <Col>
                            <Form.Control disabled={true} type="number" placeholder="limit Balance monthly" value={projectBenefits} onChange={handleProjectBenefits}/>
                          </Col>
                      </Form.Row>
                      <Row>
                        <Col>
                          <ObexAlert type='error' msg={error}/>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="4" className="ml-auto">
                        <Button disabled={submitDisabled} type="submit" className="btn-block obex-btn btn-green">
                          Update {success && <FontAwesomeIcon icon={faCheck}/>}
                          {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} onClick={handleSubmit}/>}
                        </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col className="mx-auto">
            <Row>
              <Col>
                <h1 className="mb-3">Project information</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="product-detail-card obex-card project-access-card mb-3">
                  <Card.Body className="p-3">
                    <Form className="obex-form">
                    <Form.Row className="mb-3">
                        <Form.Label column md="2" className="obex-form-label">
                          ID
                        </Form.Label>
                        <Col>
                          <InputGroup className="obex-input-group mb-3">
                            <FormControl
                              ref={IDProject}
                              placeholder={projectInfo.name}
                              aria-describedby={projectInfo.name}
                              value={projectInfo.name}
                              disabled
                            />
                            <InputGroup.Append>
                              <Button className="text-black" value="id_project" style={{ backgroundColor:'#e9ecef' }} onClick={() => {navigator.clipboard.writeText(projectInfo.name)
                                  alertaCopy(projectInfo.name);
                                  }}>
                                    {Copied==projectInfo.name ?
                                    <FontAwesomeIcon icon={faCheck}/>:
                                    <FontAwesomeIcon icon={faCopy}/>
                                    }
                                  </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Row>                      
                      <Form.Row className="mb-3">
                        <Form.Label column md="2" className="obex-form-label">
                          API Key
                        </Form.Label>
                        <Col>
                          <InputGroup className="obex-input-group mb-3">
                            <FormControl
                              ref={projectAPIKey}
                              placeholder="API Key"
                              aria-describedby={projectInfo.apikey}
                              value={projectInfo.apikey}
                              disabled
                            />
                            <InputGroup.Append>
                              <Button className="text-black" style={{ backgroundColor:'#e9ecef' }} onClick={() => {navigator.clipboard.writeText(projectInfo.apikey)
                                  alertaCopy(projectInfo.apikey);
                                  }}>
                                  {Copied==projectInfo.apikey ?
                                    <FontAwesomeIcon icon={faCheck}/>:
                                    <FontAwesomeIcon icon={faCopy}/>
                                    }
                                  </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Row>

                      {projectInfo.cipherKey &&
                        <Form.Row className="mb-3">
                          <Form.Label column md="2" className="obex-form-label">
                            Cipher Key
                          </Form.Label>
                          <Col>
                            <InputGroup className="obex-input-group mb-3">
                              <FormControl
                                ref={cipherKey}
                                placeholder="Cypher Key"
                                aria-describedby={projectInfo.cipherKey}
                                value={projectInfo.cipherKey}
                                disabled
                              />
                              <InputGroup.Append>
                                <Button className="text-black" style={{ backgroundColor:'#e9ecef' }} onClick={() => {navigator.clipboard.writeText(projectInfo.cipherKey)
                                  alertaCopy(projectInfo.cipherKey);
                                  }}>                                    
                                  {Copied==projectInfo.cipherKey ?
                                    <FontAwesomeIcon icon={faCheck}/>:
                                    <FontAwesomeIcon icon={faCopy}/>
                                    }
                                    </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                        </Form.Row>
                      }
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="my-3">Edit Fees</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="8" className="mt-2">
                      <span>
                        Edit Fees
                      </span>
                    </Col>
                    <Col md="4" className="my-auto">
                      <Button disabled={deleting} onClick={handleRouteCommission} className="btn-block obex-btn btn-green">
                        Edit Fees
                      </Button>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="my-3">Edit currencies</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="8" className="mt-2">
                      <span> 
                        Edit currencies
                      </span>
                    </Col>
                    <Col md="4" className="my-auto">
                      <Button disabled={deleting} onClick={handleRouteCurrencies} className="btn-block obex-btn btn-green">
                        Edit Currencies
                      </Button>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>




        <Row>          
          <Col>
            <h1 className="my-3">Project Users</h1>
          </Col>
        </Row>


        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                <Row style={{fontFamily: 'Regular Intro Bold'}}>
                      <Col className="m-2" style = {{maxWidth:'20%'}}>
                      Description
                      </Col>
                      <Col style = {{maxWidth:'6%'}} className="m-2">
                      Id
                      </Col>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      Nick
                      </Col>
                      <Col style = {{maxWidth:'33%'}} className="m-2">
                      PIN
                      </Col>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      Private Key
                      </Col>
                    
                  </Row>
                  <Row>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      Default Affiliate
                      </Col>
                      <Col style = {{maxWidth:'6%'}} className="m-2">
                      {usuariosProj['default_affiliate']['user_id']}
                      </Col>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      {usuariosProj['default_affiliate']['nick']}
                      </Col>
                      <Col style = {{maxWidth:'30%'}} className="m-2">
                      {usuariosProj['default_affiliate']['pin']}
                      </Col>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      {usuariosProj['default_affiliate']['private_key']}
                      </Col>
                   
                  </Row>
                  <Row>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      Admin Affiliate
                      </Col>
                      <Col style = {{maxWidth:'6%'}} className="m-2">
                      {usuariosProj['admin_affiliate']['user_id']}
                      </Col>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      {usuariosProj['admin_affiliate']['nick']}
                      </Col>
                      <Col style = {{maxWidth:'30%'}} className="m-2">
                      {usuariosProj['admin_affiliate']['pin']}
                      </Col>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      {usuariosProj['admin_affiliate']['private_key']}
                      </Col>
                    
                  </Row>

                  <Row>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      Fees Affiliate
                      </Col>
                      <Col style = {{maxWidth:'6%'}} className="m-2">
                      {usuariosProj['fees_affiliate']['user_id']}
                      </Col>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      {usuariosProj['fees_affiliate']['nick']}
                      </Col>
                      <Col style = {{maxWidth:'30%'}} className="m-2">
                      {usuariosProj['fees_affiliate']['pin']}
                      </Col>
                      <Col style = {{maxWidth:'20%'}} className="m-2">
                      {usuariosProj['fees_affiliate']['private_key']}
                      </Col>
                    
                  </Row>




                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>



        <Row>          
          <Col>
            <h1 className="my-3">Delete project</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="8" className="">
                      <span>By closing your account, you will lose access to 
                        your financial data and your key will expire. 
                        This is irreversible.</span>
                    </Col>
                    <Col md="4" className="my-auto">
                      <Button disabled={deleting} onClick={handleShowModal} className="btn-block obex-btn btn-orange">
                        Delete Project
                      </Button>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>



        <ConfirmDeleteProjectModal 
          handleSubmit={handleDeleteProject} 
          showModal={showModal}
          onCloseModal={handleShowModal}
          loading={deleting}
          error={error}
          success={success}
          name_to_check={projectInfo.name}
          title_to_show={'project'}
        />
      </Col>
    </Row>
            }
  </DashLayout>
  )
}
// TODO possibleProjectsToBuy rescartar la info
export default ProjectInfo;