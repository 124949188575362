import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, InputGroup, FormControl, AlertProps, Spinner } from 'react-bootstrap';
import PhoneVerificationModal from '../../Modals/phoneVerificationModal';
import AddBankAccount from '../../Modals/addBankAccount';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck  } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';


const PayoutsContent:FunctionComponent<{}> = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [locality, setLocality] = useState('');
  const [currency, setCurrency] = useState('');
  const [accountName, setAccountName] = useState('')
  const [accountHolderName, setAccountHolderName] = useState('')
  const [accountHolderType, setAccountHolderType] = useState('')
  const [rountingNumber, setRountingNumber] = useState('')

  const countriesList = countries.map(c => <option value={c.country_code} selected={locality == c.country_code }>{c.country}</option>);

  const getCountries = async () => {
    try {
      const result = (await ObexRequestHandler.get('/countries')).data;
      setCountries(result);
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }
  }

  const handleCountry = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setLocality(value);
  }

  const handleCurrency = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrency(e.target.value);
  }

  const handleAccountName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAccountName(e.target.value);
  }

  const handleAccountHolderName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAccountHolderName(e.target.value);
  }

  const handleAccountHolderType = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAccountHolderType(e.target.vlue);
  }

  const handleRoutingNumber = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setRountingNumber(e.target.value);
  }

  useEffect(() =>{
    getCountries();
  }, [])

  return(
    <DashLayout sider={DashRouters} active={'income'}>
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
              <h1 className="mb-3">Add Payouts</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <Row>
                    <Col className="mx-auto">
                      <Form className="obex-form">
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Country
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required as="select" placeholder='Country' custom onChange={handleCountry}>
                              <option value="" selected disabled hidden>Choose country</option>
                              {countriesList}
                            </Form.Control>  
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Currency
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required type="text" placeholder="Currency" onChange={handleCurrency}/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Account name
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required type="text" placeholder="Account name" onChange={handleAccountName}/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Account holder's name
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required type="text" placeholder="Account holder's name" onChange={handleAccountHolderName}/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Account type
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required type="text" placeholder="Account type" onChange={handleAccountHolderType}/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Routing number
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required type="text" placeholder="Routing number" onChange={handleRoutingNumber}/>
                          </Col>
                        </Form.Row>
                        <Row className="mt-3">
                          <Col md="4" className="ml-auto">
                          <Button type="submit" disabled={submitDisabled} className="btn-block obex-btn btn-green">
                            Update {success && <FontAwesomeIcon icon={faCheck}/>}
                            {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                          </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashLayout>
  )
}

export default PayoutsContent;