import React, { FunctionComponent } from 'react'; 
import { Container, Navbar, Nav, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


const PublicPage:FunctionComponent<{}> = () => {

  return(
    <Container>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand href="#home">101Obex</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="col">
              <Nav.Link href="#" className="mx-auto">Home</Nav.Link>
              <Nav.Link href="#" className="mx-auto">Pricing</Nav.Link>
              <Nav.Link href="#" className="mx-auto">Contact Sales</Nav.Link>
              <Nav.Link href="#" className="mx-auto">Try the Sandbox</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Row>
        <Col md="6" className="mr-auto">
          <Row>
            <Col md="12">
              <span>
                The smartest way to develop your fintech services
              </span>
            </Col>
            <Col md="12">
              <span>
                Partner withc the FaaS (Fintech as a Service) service trusted by developers, 
                corporates and financial institutions for time-savings, scalability, and 
                delivery expertise.
              </span>
            </Col>
            <Col md="12">
              <Button>See Plans & Pricings</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <span>The open banking platform for some of the world’s leading brands</span>
        </Col>
        <Col md="12">
          <img src="" alt=""/>
        </Col>
      </Row>
      <Row>
        <Col md="12"><span>Why 101OBex</span></Col>
      </Row>
      <Row>
        <Col md="6"><img src="" alt=""/></Col>
        <Col md="6"><span>Our checkout is designed to be seamless and secure -- whether you choose our ready-made UI or tailor-make your own. Give buyers a checkout with fewer clicks and a friction-free user experience that can help reduce cart abandonment, increase conversion rates, and encourage repeat business.</span></Col>
      </Row>
      <Row>
        <Col md="6">
          <span>Increase Efficiency</span>
          <span>Our modern, developer-friendly SDK is thoroughly documented and can help your business bring new payment methods -- including PayPal, Venmo (in the US), and more -- to market quickly in a single integration.</span>
        </Col>
        <Col md="6"><img src="" alt=""/></Col>
      </Row>
      <Row>
        <Col md="6"><img src="" alt=""/></Col>
        <Col md="6">
          <span>Expand your Reach</span>
          <span>Reach new segments with a single integration that gives you access to multiple currencies, global markets, and popular payment methods including PayPal, Venmo (in the US), cards, wallets, and more in a single integration.</span>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <span>Help Mitigate Risk</span>
          <span>From basic fraud tools like AVS, CVV, and risk thresholds, to more advanced options like 3D Secure and solutions that leverage big-data analytics and cutting-edge machine learning, we’ve got you covered.</span>
        </Col>
        <Col md="6"><img src="" alt=""/></Col>
      </Row>
      <Row>
        <Col md="12">
          <Row>
            <Col md="3">
              <Button>See Plans & Princing</Button>
            </Col>
          </Row>
        </Col>
        <Col md="12">
          <Row>
            <Col md="8">
              <span>To schedule a product demo or activate your account, please fill in your contact details</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="10" className="mx-auto">
          <Form>
            <Row>
              <Col md="6">
                <Form.Control placeholder="Name"/>
              </Col>
              <Col md="6">
                <Form.Control type="email" placeholder="Email"/>
              </Col>
              <Col md="12">
                <Form.Control as="textarea" rows={3} />
              </Col>
              <Col md="12">
                <Button type="submit">Submit</Button>
              </Col>
            </Row>
            <Row>
              <Col><span>info@101obex.com</span></Col>
              <Col><span>Tel: +1 650 688 88 42</span></Col>
              <Col><span>703 N California Ave Palo Alto, CA 94303</span></Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="pricing-box mx-auto mb-2">
        <Col md={2} className="mx-auto social-icons text-center">
          <a href="#" className="mx-2"><FontAwesomeIcon icon={faFacebookF} /></a>
          <a href="#" className="mx-2"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="#" className="mx-2"><FontAwesomeIcon icon={faLinkedinIn} /></a>
        </Col>
      </Row>
      <Row className="pricing-box mx-auto">
        <Col className="text-center">
          <p className="copyright">&copy; 2019 by 101 OBeX a WAYNNOVATE Financial Technology Hub inniative</p>
        </Col>
      </Row>
    </Container>
  )
}

export default PublicPage;