import React, { FunctionComponent, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import BaseLayout from '../functionalComponents/baseLayout/BaseLayout';
import ReactMarkdown from 'react-markdown';
import '../../assets/css/prism.css';
import '../../assets/css/contentdoc.css';

type ContentProps = {
    doc: string,
    sider: any,
    cat: string
};

const Content:FunctionComponent<ContentProps> = props => {

  const { doc, cat, sider } = props;
  const md = require(`../../assets/docs/${doc}.md`);
  
  useEffect(() => {
    // Importamos prism cada vez que cambia el markdown file y ejecutamos el highlightAll
    const prism = require('../../assets/js/prism.js');
    prism.highlightAll();
  }, [doc])

  return (
    <BaseLayout sider={sider} active={cat}>
      <Row className="justify-content-md-center">
        <Col md={{ span: 12 }}>
            <ReactMarkdown source={md} escapeHtml={false} className="markdown mt-5 mr-4 ml-4"/>
        </Col>
      </Row>
    </BaseLayout>
  )
}

export default Content;