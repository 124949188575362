import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col,  } from 'react-bootstrap';
import ConsumptionChart from './GraphComponents/ConsumptionChart';
//import ConsumptionChart from './GraphComponents/LineChart2';
import Consumptions from './Consumption/Consumptions';
import CategoriesConsumption from './Consumption/CategoriesConsumption';

type DashProps = {
    sider: any
};


const DashboardContent:FunctionComponent<DashProps> = ({ sider }) => {
  
  return (
    <DashLayout sider={sider}>
      <Consumptions/>
      <Row className="">
        <Col className="mt-4 p-0">
          <ConsumptionChart/>
        </Col>
      </Row>
      <CategoriesConsumption/>
    </DashLayout>
  )
}

export default DashboardContent;