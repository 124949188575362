import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Card, Button, Container, Spinner  } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin, parseToNumberFormat } from '../../services/AmountService';
import VerifyCancelSubModal from '../Modals/VerifyCancelSubModal';

type subProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  monthRateLimit: number,
  maxMonthRateLimit: number,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean
}


const SubscriptionContent:FunctionComponent<subProps> = (props: subProps) => {

  const { name, price, purchased, expires, 
          monthRateLimit, maxMonthRateLimit, 
          annualPayment, active, renovate } = props;

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal(!showModal);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteSubscription = async() => {
    try {
      setError('');
      setSuccess('');
      setLoading(true);
      const result = await ObexRequestHandler.delete('/subscription');
      const { success } = result;
      if (success){
        setSuccess(success);
        setLoading(false);
        setTimeout(function() { 
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      }else{
        setError(error)
      }
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteSubscription();
  }

  return (
    <Row className="mb-3">
      <Col md="12">
        <Card className="product-detail-card mb-3 obex-card">
          <Card.Body className="p-0">
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>Type to subcription: <span className="text-bold">{name}</span></span>
                </Col>
                <Col md="4" className="text-right">
                <span className="text-bold">{parseToCoin(price)}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Purchased</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{purchased}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Expires</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{expires}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>Monthly rate limit</span>
                </Col>
                <Col md="4" className="text-right">
                <span><span className="text-bold">{parseToNumberFormat(monthRateLimit)}</span> Requests</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>Max Monthly rate limit</span>
                </Col>
                <Col md="4" className="text-right">
                <span><span className="text-bold">{parseToNumberFormat(maxMonthRateLimit)}</span> Requests</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 py-3">
              <Row>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Annual Payment</span></Col>
                    <Col md="12" className="text-center"><span>{annualPayment ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Active</span></Col>
                    <Col md="12" className="text-center"><span>{active ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Renovate</span></Col>
                    <Col md="12" className="text-center"><span>{renovate ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Row>

        </Row>
      </Col>
      <VerifyCancelSubModal
      showModal={showModal}
      onCloseModal={handleModal}
      handleSubmit={handleSubmit}
      error={error}
      success={success}
      loading={loading}
      name_to_check={name}
        />
    </Row>
  )
}


type extraProjectProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean,
  id: number
}

const SubExtraProject:FunctionComponent<extraProjectProps> = (props: extraProjectProps) =>{

  const { name, price, purchased, expires, annualPayment, active, renovate,
        id } = props;
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal(!showModal);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteExtraProjects = async(id) => {
    try {
      setError('');
      setSuccess('');
      setLoading(true);
      const result = await ObexRequestHandler.delete(`/extraprojects/${id}`);
      const { success } = result;
      if (success){
        setSuccess(success);
        setLoading(false);
        setTimeout(function() { 
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      }else{
        setError(error)
      }
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    deleteExtraProjects(id);
  }

  return(
    <Row className="mb-3">
      <Col md="12">
        <Card className="product-detail-card mb-3 obex-card">
          <Card.Body className="p-0">
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>{name}</span>
                </Col>
                <Col md="4" className="text-right">
                  <span className="text-bold">{parseToCoin(price)}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Purchased</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{purchased}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Expired</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{expires}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 py-3">
              <Row>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Annual Payment</span></Col>
                    <Col md="12" className="text-center"><span>{annualPayment ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Active</span></Col>
                    <Col md="12" className="text-center"><span>{active ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Renovate</span></Col>
                    <Col md="12" className="text-center"><span>{renovate ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="4" className="my-auto">
                      <span>Cancel Subscription</span>
                    </Col>
                    <Col md="8" className="">
                      <Row>
                        <Col md="6" className="ml-auto">
                          <Button className="btn-block obex-btn btn-orange" onClick={handleModal} disabled={!renovate}>Cancel Subscription</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <VerifyCancelSubModal 
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={(e) => handleSubmit(e, id)}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name}/>
    </Row>
  )
}

type extraRequestProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean,
  id: number
}

const SubExtraRequest:FunctionComponent<extraRequestProps> = (props: extraRequestProps) =>{

  const { name, price, purchased, expires, annualPayment, active, renovate,
          id } = props;

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal(!showModal);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteExtraRequests = async(id) => {
    try{
      setError('');
      setLoading(true);
      const result = await ObexRequestHandler.delete(`/extrarequests/${id}`);
      const { success } = result;
      if (success){
        setSuccess(success);
        setLoading(false);
        setTimeout(function() { 
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      }
      setError(error)
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(e);
    console.log(id)
    deleteExtraRequests(id);
  }

  return(
    <Row className="mb-3">
      <Col md="12">
        <Card className="product-detail-card mb-3 obex-card">
          <Card.Body className="p-0">
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>{name}</span>
                </Col>
                <Col md="4" className="text-right">
                  <span className="text-bold">{parseToCoin(price)}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Purchased</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{purchased}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Expired</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{expires}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 py-3">
              <Row>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Annual Payment</span></Col>
                    <Col md="12" className="text-center"><span>{annualPayment ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Active</span></Col>
                    <Col md="12" className="text-center"><span>{active ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Renovate</span></Col>
                    <Col md="12" className="text-center"><span>{renovate ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="4" className="my-auto">
                      <span>Cancel Subscription</span>
                    </Col>
                    <Col md="8" className="">
                      <Row>
                        <Col md="6" className="ml-auto">
                          <Button className="btn-block obex-btn btn-orange" 
                            onClick={handleModal} 
                            disabled={!renovate}>Cancel Subscription</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <VerifyCancelSubModal 
        showModal={showModal} 
        onCloseModal={handleModal} 
        handleSubmit={(e) => handleSubmit(e, id)}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name}
        />
    </Row>
  )
}


type planSubcriptionProps = {
  sider: any
}

const PlanSubscriptionContent:FunctionComponent<planSubcriptionProps> = (props: planSubcriptionProps) => {

  const [subContent, setSubContent] = useState(<div></div>);
  const [projectsPlans, setProjectsPlans] = useState([]);
  const [requestsPlans, setRequestPlans] = useState([]);
  const [stillLoaded, setStillLoaded] = useState(true);
  // const [priceSuscription, SetPrecio] = useState(0);

  const getCurrentSubcription = async() => {
    const result = (await ObexRequestHandler.get('/purchased_services')).data || {};
    // console.log(result);
    if (result){
      const { subscription, extraProjectsPlans, extraRequestsPlans } = result;
      let suscriptionPrice = 0;
      
      if (!subscription.annual_payment) 
      {
        /*SetPrecio(subscription.monthly_price);*/suscriptionPrice=subscription.monthly_price;
      } else 
      {
        /*SetPrecio(subscription.anual_price * 10);*/suscriptionPrice=(subscription.annual_price * 10);
      }
      
      setSubContent(<SubscriptionContent 
                    name={subscription.name}
                    price={suscriptionPrice}
                    purchased={subscription.purchased}
                    expires={subscription.expires}
                    monthRateLimit={subscription.month_rate_limit}
                    maxMonthRateLimit={subscription.max_month_rate_limit}
                    annualPayment={subscription.annual_payment}
                    active={subscription.active}
                    renovate={subscription.renovate}/>);
      setProjectsPlans(extraProjectsPlans);
      setRequestPlans(extraRequestsPlans);
    }
    setStillLoaded(false);
  }

  useEffect(() => {
    getCurrentSubcription();
  }, [])

  return(
    <DashLayout sider={props.sider} active={'plan_subcription'}>
      
      {stillLoaded ?
      <Container className="vh-100 d-flex flex-column vw-100"> 
        <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center">
            <Spinner animation="border"/>
          </Col>
        </Row>
      </Container>
      :

       <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Subcription</h1>
            </Col>
          </Row>
          {subContent}
          <Row>
            <Col>
            {projectsPlans.length>0 &&
            <h1 className="mb-3">Extra Projects Plan</h1>}
            </Col>
          </Row>
          {projectsPlans.map(plan => 
            <SubExtraProject  
              key={'SubExtraProject'+plan.id+Math.random()}
              name={plan.title}
              price={plan.monthly_price}
              purchased={plan.purchased}
              expires={plan.expires}
              annualPayment={plan.annual_payment}
              active={plan.active}
              renovate={plan.renovate}
              id={plan.purchase_id} />
          )}
          <Row>
            <Col>
            {requestsPlans.length>0 &&
            <h1 className="mb-3">Extra Requests Plans</h1>}
            </Col>
          </Row>
          {requestsPlans.map(plan =>
            <SubExtraRequest
              key={'SubExtraRequests'+plan.id+Math.random()}
              name={plan.title}
              price={plan.monthly_price}
              purchased={plan.purchased}
              expires={plan.expires}
              annualPayment={plan.annual_payment}
              active={plan.active}
              renovate={plan.renovate}
              id={plan.purchase_id} />
          )}
        </Col>
      </Row>
      }
    </DashLayout>
  )
}

export default PlanSubscriptionContent;