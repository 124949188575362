import React, { FunctionComponent } from 'react';
import { Button, Row, Col, Image } from 'react-bootstrap';
import ImgSerpentina from '../../assets/img/ilustracion-serpentina.png';



type PaymentSuccessprops = {
  onCloseAction: () => void,
  paymentType: string,
  paymentDescription: string
}


const PaymentSuccessModal:FunctionComponent<PaymentSuccessprops> = (props) => {

  const { onCloseAction, paymentType, paymentDescription } = props;

  return (
    <Row>
      <Row className="mx-auto mt-4 mb-2">{paymentType}</Row>
      <Col md="10" className="mx-auto">
        <Row>
          <Col md="6" className="mt-4 mb-5 mx-auto">
            <Image src={ImgSerpentina} fluid/>
          </Col>
        </Row>
        <Row>
          <Col md="4" className="mx-auto mb-3">
            <Button className="btn-block btn-green" onClick={onCloseAction}>Accept</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default PaymentSuccessModal;