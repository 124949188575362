import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Button, Form, Table, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import AddOrganizationModal from '../Modals/addOrganizationModal';
import EditOrganizationModal from '../Modals/editOrganizationModal';


type IncomeProps = {
};


const Organizations:FunctionComponent<IncomeProps> = ({ }) => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [filtro, setFilter] = useState('');
  const [selected_org, setSelectedOrg] = useState(0);
  const [selected_org_name, setSelectedOrgName] = useState('');
  const [selected_org_description, setSelectedOrgDescription] = useState('');
  const [currentOrganizations, setCurrentOrganizations] = useState(0);
  const [maxOrganizations, setMaxOrganizations] = useState(0);
  const [loading, setLoading] = useState(true);

  const changeFilter = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
  }


  const handleModal = () => {
    getProjects('');
    setShowModal(!showModal);
  };

  const handleModal2 = () => {
    getProjects('');
    setShowModal2(!showModal2);
  };

  const getProjects = async (filtro) => {
    try {
      setLoading(true);
      setProjects([]);

      const projects = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];

      setCurrentOrganizations(projects['result'].length);
      setMaxOrganizations(projects['max']);


      if (filtro=='') setProjects(projects['result']);
      else {

        const filter_projects = [];
        projects['result'].forEach(element => {
          if (element.name.includes(filtro)) filter_projects.push(element);
        });

        setProjects(filter_projects);
      }

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setLoading(false);

    }
    setLoading(false);

  }


  useEffect(() => {
    getProjects('');
  }, []);


  return(
    <DashLayout sider={DashRouters} active={'organizations'} sublinks={[]}>
      <Row>
        <Col md="9" className="mx-auto" >


       <Row style={{ marginTop:'20px' }}>
        <Col md={9} style={{ marginLeft : '-20px' }}><h1>Manage Organizations</h1></Col>
        <Col md={3} style={{ paddingLeft : '-0px' }}>


              { currentOrganizations < maxOrganizations ?
              <Button disabled={null} className="btn-add obex-btn btn-add-green btn-block px-4"
               onClick={() => {setShowModal(true)}}>Create Organization</Button>
                :
                <Button disabled={null} className="btn-add obex-btn btn-add-green btn-block px-4" 
                onClick={() => {history.push('/plan_subcription/upgrade_plan')}}>Create Organization</Button>
              }

        </Col>
        
       </Row>
       <Form.Row >
        <Col md="9" style={{ marginLeft : '-20px' }}>
          <Form.Control required type="text" value={filtro} placeholder="Search by Organization name" 
          onChange={changeFilter} />
        </Col>
      
            <Col md={3}>
              <Button disabled={null} className="btn-add obex-btn btn-add-green btn-block px-4" 
              onClick={() => {getProjects(filtro)}}><FontAwesomeIcon icon={faSearch} size="1x" 
              className="mr-1"/></Button>
            </Col>
        </Form.Row>



       <Row style = {{ marginTop:'20px' }}>
        <Col style={{ marginLeft : '-20px' }}>

        {loading &&

          <Row style={{ textAlign: 'center' }}>
            <Col>
              <Spinner animation="border" style={{ marginLeft: '8px' }}/>
            </Col>
          </Row>

          }

        { !loading &&

        <Table responsive className="obex-projects-table">
          <thead style={{ fontFamily: 'Regular Intro Bold' }}>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th># Of Developers</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

            {projects.map(project =>  (
              <tr key={project.id}>
                <td>{project.name}</td>
                <td>{project.description}</td>
                <td>{project.developers}</td>
                <td className="text-right">
                  <a className="edit-link"  onClick={async () => {
                      setSelectedOrg(project.id);
                      setSelectedOrgName(project.name);
                      setSelectedOrgDescription(project.description)
                      setShowModal2(true);
                    }
                    }><FontAwesomeIcon icon={faPencilAlt} size="1x" className="mr-1"/></a>
                  </td>
              </tr>
            ))}
          </tbody>
          </Table>
      }
        </Col>
      </Row>
      {showModal && 
        <AddOrganizationModal 
          show={showModal}  
          handleClose={handleModal}/>
      }
      {showModal2 && 
        <EditOrganizationModal show={showModal2} organization={selected_org} 
          organization_name={selected_org_name} organization_description={selected_org_description} 
          handleClose={handleModal2}/>
      }
       </Col>
      </Row>
    </DashLayout>
  )
}

export default Organizations;


