import RequestHandler from './RequestHandler';
import { url, port } from '../configs/appConfig.json';
import Cookies from 'universal-cookie';

type ObexHeaders = {
  Authorization:string,
  'disable-cache':boolean
}

class ObexRequestHandler {
  private static url = `${url}:${port}`;
  private static cookies = new Cookies();

  private static getAuthToken() {
    const user = ObexRequestHandler.cookies.get('101Obex');
    if (!user) {return '';}
    const { token } = user;
    return `Bearer ${token}`;
  }
  private static getHeaders(disableCache = false): ObexHeaders { 
    const headers = { Authorization: '', 'disable-cache': disableCache };
    const token = this.getAuthToken();
    headers.Authorization = token;
    return headers;
  }

  public static async get(path: string, params?: object, disableCache = false): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders(disableCache);
      if (headers.Authorization==''){
        if ((!path.includes('/subscription')) &&
            (path!='/subscriptions') && 
            (path!='/extraprojects/plans') &&
            (path!='/extrarequests/plans') &&
            (path!='/countries') &&
            !(path.includes('validation')) &&
            !(path.includes('/open_banking_tokens')) &&
            !(path.includes('/request_reset_password')) &&
            !(path.includes('/reset_password'))
            ) {window.location.replace('/login');}
        else {}

      }
       
      const result = await RequestHandler.get(url, params, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async post(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();


      if (headers.Authorization==''){
        if ((path!='/signin')  && 
            (path!='/signup') &&
            (path!='/resend_validation') &&
            (path!='/checkpayment') &&
            (path!='/checkauthsetup') &&
            (path!='/resend_validation') &&
            (path!='/request_reset_password') &&
            (path!='/reset_password') &&
            (path!='/finish_signup')
            
            ) {
              window.location.replace('/login');}
        else {}
      }


      const result = await RequestHandler.post(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async put(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.put(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async patch(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.patch(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async delete(path: string, params?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.delete(url, params, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(delete)', error)
    }
  }

}

export default ObexRequestHandler;